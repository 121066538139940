<!--门禁模块--滞留人员区域-->
<template>
  <div
    id="detentionArea"
    v-loading="loading"
    :element-loading-text="$t('detentionArea.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("detentionArea.a2") }}</my-headertitle>
      <div class="header-inquire">
        <div>
          <p>{{ $t("detentionArea.a3") }}</p>
          <a-cascader
            style="width: 250px"
            :options="options"
            change-on-select
            v-model="select"
            @change="change"
            :allowClear="false"
            :field-names="{
              label: 'title',
              value: 'key',
              children: 'children',
            }"
          />
        </div>
        <div>
          <a-button type="primary" style="width: 120px" @click="inquire">
            {{ $t("detentionArea.a4") }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="main" ref="main" v-table-size="tableSize">
      <my-tabletitle>{{ $t("detentionArea.a5") }}</my-tabletitle>
      <a-table
        :columns="columns"
        :dataSource="tableDate"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.readerId"
      >
      </a-table>
    </div>
    <div class="footer">
      <a-button type="primary" v-if="select.length < 3" @click="areaAdd">
        {{ $t("detentionArea.a6") }}
      </a-button>
      <a-button type="primary" v-if="select.length > 1" @click="areaDelete">
        {{ $t("detentionArea.a7") }}
      </a-button>
      <a-button type="primary" v-if="select.length > 2" @click="areaCompile">
        {{ $t("detentionArea.a8") }}
      </a-button>
      <a-button type="primary" v-if="select.length > 1" @click="Changename">
        {{ $t("detentionArea.a9") }}
      </a-button>
    </div>

    <!-- 添加区域/分组 -->
    <a-modal
      :title="$t('detentionArea.a6')"
      centered
      :width="300"
      :visible="addvisible"
      :getContainer="() => this.$refs.main"
      @cancel="addClose"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="addClose" :disabled="disabled">
          {{ $t("detentionArea.a11") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="addSet"
          :disabled="disabled"
        >
          {{ $t("detentionArea.a10") }}
        </a-button>
      </template>
      <div
        class="addArea"
        v-loading="addLoading"
        :element-loading-text="$t('detentionArea.a1')"
      >
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item :label="$t('detentionArea.a12')">
            <a-radio-group v-model="form.value">
              <a-radio :value="1" :disabled="area">
                {{ $t("detentionArea.a13") }}
              </a-radio>
              <a-radio :value="2" :disabled="grouping">
                {{ $t("detentionArea.a14") }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :label="$t('detentionArea.a15')" prop="addName">
            <a-input
              v-model="form.addName"
              allow-clear
              style="width: 200px"
              :placeholder="$t('detentionArea.a30')"
              v-InputText
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

    <!-- 变更名称 -->
    <a-modal
      :title="$t('detentionArea.a9')"
      centered
      :width="300"
      :visible="namevisible"
      :getContainer="() => this.$refs.main"
      @cancel="nameClose"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="nameClose" :disabled="disabled">
          {{ $t("detentionArea.a11") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="alterationName"
          :disabled="disabled"
        >
          {{ $t("detentionArea.a10") }}
        </a-button>
      </template>
      <div
        class="updateName"
        v-loading="nameLoading"
        :element-loading-text="$t('detentionArea.a1')"
      >
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item :label="$t('detentionArea.a16')" prop="areaName">
            <a-input
              v-model="form.areaName"
              allow-clear
              style="width: 200px"
              :placeholder="$t('detentionArea.a30')"
              v-InputText
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

    <!-- 滞留区域设置 -->
    <a-modal
      :title="$t('detentionArea.a17')"
      centered
      :width="800"
      :visible="areaVisible"
      :getContainer="() => this.$refs.main"
      @cancel="areaclose"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="areaclose" :disabled="disabled">
          {{ $t("detentionArea.a11") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="areaSet"
          :disabled="disabled"
        >
          {{ $t("detentionArea.a10") }}
        </a-button>
      </template>
      <div
        class="modal"
        v-loading="arealoading"
        :element-loading-text="$t('detentionArea.a1')"
      >
        <div class="modal_header">
          <span> {{ $t("detentionArea.a18") }}</span>
          <span>{{ areaName }}</span>
        </div>
        <div class="modal_main">
          <my-tabletitle> {{ $t("detentionArea.a19") }}</my-tabletitle>
          <a-table
            :columns="ownerColumns"
            :dataSource="allDoorTableList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.readerId"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => doorAdd(record)">
                {{ `${$t("detentionArea.a20")}  ↓` }}</a-button
              >
            </template>
          </a-table>
          <a-divider />
          <my-tabletitle>{{ $t("detentionArea.a21") }}</my-tabletitle>
          <a-table
            :columns="ownerColumns"
            :dataSource="registerList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.readerId"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => doorDelete(record)">
                {{ `${$t("detentionArea.a22")}  ↑` }}
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import {
  doorTimetableListKT,
  areaDeviceInfo,
  areaListAdd,
  areaListDelete,
  areaNameUpdate,
  allAreaDeviceInfo,
  areaCompileSet,
} from "../../api/door";
import { tableSort } from "../../utils/utils";

export default {
  name: "detentionArea",
  data() {
    return {
      visible2: true,
      loading: false,
      addvisible: false,
      addLoading: false,
      nameLoading: false,
      namevisible: false,
      areaVisible: false,
      arealoading: false,
      disabled: false,
      form: {
        areaName: "",
        copyareaName: "",
        addName: "",
        value: "",
      },
      areaName: "",
      copyareaName: "",
      size: { y: 240 },
      registerList: [],
      allDoorTableList: [],
      tableDate: [],
      options: [],
      select: [],
      rules: {
        areaName: [
          {
            required: true,
            message: () => {
              return this.$t("detentionArea.a30");
            },
            trigger: "change",
          },
        ],
        addName: [
          {
            required: true,
            message: () => {
              return this.$t("detentionArea.a30");
            },
            trigger: "change",
          },
        ],
      },
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("detentionArea.a24")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          title: this.$t("detentionArea.a25"),
          dataIndex: "deviceName",
          sorter: (a, b) => {
            return tableSort(a, b, "deviceName");
          },
          align: "center",
        },
        {
          title: this.$t("detentionArea.a26"),
          dataIndex: "readerName",
          sorter: (a, b) => {
            return tableSort(a, b, "readerName");
          },
          align: "center",
        },
        {
          title: this.$t("detentionArea.a27"),
          dataIndex: "floorTenantName",
          sorter: (a, b) => {
            return tableSort(a, b, "floorTenantName");
          },
          align: "center",
        },
      ],
      ownerColumns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          title: this.$t("detentionArea.a25"),
          dataIndex: "deviceName",
          sorter: (a, b) => {
            return tableSort(a, b, "deviceName");
          },
          align: "center",
        },
        {
          title: this.$t("detentionArea.a26"),
          dataIndex: "readerName",
          sorter: (a, b) => {
            return tableSort(a, b, "readerName");
          },
          align: "center",
        },
        {
          title: this.$t("detentionArea.a27"),
          dataIndex: "floorTenantName",
          sorter: (a, b) => {
            return tableSort(a, b, "floorTenantName");
          },
          align: "center",
        },
        {
          title: this.$t("detentionArea.a28"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
        },
      ],
    };
  },
  watch: {
    select: {
      handler: function (newValue, oldValue) {
        const length = newValue.length;
        if (length == 1) {
          this.form.value = 1;
        } else if (length == 2) {
          this.form.value = 2;
        }
      },
    },
  },
  mounted() {
    this.areaListGet();
  },
  computed: {
    grouping() {
      const length = this.select.length;
      if (length == 1) {
        return true;
      } else {
        return false;
      }
    },
    area() {
      const length = this.select.length;
      if (length == 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("detentionArea");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title =
          indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 118.5 - title;
        this.size.y = tableHeight;
      }
    },
    // 级联选择回调
    change(value, selectedOptions) {
      const length = selectedOptions.length - 1;
      const areaName = selectedOptions[length].title;
      this.areaName = areaName;
      this.form.areaName = areaName;
    },
    // 区域列表获取
    areaListGet() {
      this.options = [];
      this.select = [];
      this.loading = true;
      const data = {
        siteId: this.$route.query.id,
        schType: "13",
      };
      doorTimetableListKT(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          this.options.push(data);
          this.select.push(data.key);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 滞留人员区域列表的查询方法
    inquire() {
      this.loading = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        siteId: this.$route.query.id,
        areaId,
        kind: 0,
      };
      areaDeviceInfo(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          this.tableDate = data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 区域列表添加按钮
    areaAdd() {
      this.addvisible = true;
      this.form.addName = "";
    },
    // 添加分组/区域设置按钮
    addSet() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addLoading = true;
          this.disabled = true;
          const length = this.select.length;
          let data = {};
          if (length == 1) {
            data = {
              areaGrId: this.select[0].split(",")[1],
              areaGrName: this.form.addName,
              kind: 0,
              mode: 0,
              rootId: this.select[0].split(",")[1],
              siteId: this.$route.query.id,
            };
          } else {
            data = {
              areaGrId: this.select[1].split(",")[1],
              areaName: this.form.addName,
              kind: 0,
              mode: 1,
              siteId: this.$route.query.id,
            };
          }
          areaListAdd(data)
            .then((res) => {
              // console.log(res);
              this.addLoading = false;
              this.disabled = false;
              const { errorCode, msg } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
                this.addvisible = false;
                this.areaListGet();
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.addLoading = false;
              this.disabled = false;
            });
        } else {
          return false;
        }
      });
    },
    // 添加分组/区域关闭按钮
    addClose() {
      this.addvisible = false;
    },
    // 删除分组/区域
    areaDelete() {
      this.$confirm({
        title: `${this.$t("detentionArea.a31")} ${this.areaName} ${this.$t(
          "detentionArea.a32"
        )}`,
        centered: true,
        onOk: () => {
          const length = this.select.length;
          let data = {};
          if (length == 2) {
            data = {
              areaGrId: this.select[1].split(",")[1],
              kind: 0,
              mode: 1,
              siteId: this.$route.query.id,
            };
          } else {
            data = {
              areaId: this.select[2].split(",")[1],
              kind: 0,
              mode: 2,
              siteId: this.$route.query.id,
            };
          }
          // console.log(data);
          areaListDelete(data)
            .then((res) => {
              // console.log(res);
              const { errorCode, msg } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
                this.areaListGet();
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },
    // 变更名称按钮
    Changename() {
      this.namevisible = true;
      this.form.copyareaName = this.form.areaName;
    },
    // 变更名称确定按钮
    alterationName() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.disabled = true;
          this.nameLoading = true;
          const length = this.select.length;
          let data = {};
          if (length == 2) {
            data = {
              areaGrId: this.select[1].split(",")[1],
              areaGrName: this.form.areaName,
              kind: 0,
              mode: 1,
              siteId: this.$route.query.id,
            };
          } else {
            data = {
              areaId: this.select[2].split(",")[1],
              areaName: this.form.areaName,
              kind: 0,
              mode: 2,
              siteId: this.$route.query.id,
            };
          }
          areaNameUpdate(data)
            .then((res) => {
              this.nameLoading = false;
              this.disabled = false;
              const { errorCode, msg } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
                this.namevisible = false;
                this.areaListGet();
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.nameLoading = false;
              this.disabled = false;
            });
        } else {
          return false;
        }
      });
    },
    // 变更名称关闭按钮
    nameClose() {
      this.namevisible = false;
      this.form.areaName = this.form.copyareaName;
    },
    // 区域编辑按钮
    async areaCompile() {
      this.areaVisible = true;
      this.arealoading = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        siteId: this.$route.query.id,
        areaId,
        kind: 0,
      };
      // console.log(data);
      await areaDeviceInfo(data)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          this.registerList = data;
        })
        .catch((err) => {
          console.log(err);
        });
      const date = {
        siteId: this.$route.query.id,
        kind: 0,
      };
      await allAreaDeviceInfo(date)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          this.allDoorTableList = data;
        })
        .catch((err) => {
          console.log(err);
        });
      for (let i = 0; i < this.registerList.length; i++) {
        let id = this.registerList[i].readerId;
        let list = this.allDoorTableList;
        let result = list.findIndex((row) => row.readerId === id);
        list.splice(result, 1);
      }
      this.arealoading = false;
    },
    // 滞留区域设置--列表添加
    doorAdd(value) {
      // console.log(value);
      const id = value.readerId;
      let list = this.allDoorTableList;
      this.registerList.push(value);
      let result = list.findIndex((row) => row.readerId === id);
      list.splice(result, 1);
    },
    // 滞留区域设置--列表删除
    doorDelete(value) {
      // console.log(value);
      const id = value.readerId;
      let list = this.registerList;
      this.allDoorTableList.unshift(value);
      let result = list.findIndex((row) => row.readerId === id);
      list.splice(result, 1);
    },
    // 滞留区域设置--设置按钮
    areaSet() {
      this.arealoading = true;
      this.disabled = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        areaId: areaId,
        kind: 0,
        siteId: this.$route.query.id,
        areaDeviceInfoList: this.registerList,
      };
      areaCompileSet(data)
        .then((res) => {
          this.arealoading = false;
          this.disabled = false;
          const { errorCode, msg } = res;
          if (errorCode == "00") {
            this.$message.success(msg);
            const _val = JSON.stringify(this.registerList);
            const val = JSON.parse(_val);
            this.tableDate = val;
          } else {
            this.$message.error(msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.arealoading = false;
          this.disabled = false;
        });
    },
    // 滞留区域设置--关闭按钮
    areaclose() {
      this.areaVisible = false;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 0px;
}
#detentionArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 140px;
  padding: 0 20px;
  overflow: hidden;
}
p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000a6;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-inquire {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 89px;
  padding: 15px 0px;
  overflow: hidden;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.main {
  height: calc(100% - 192px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.footer button {
  margin-left: 15px;
}
.modal {
  width: 100%;
}
.modal_header {
  padding: 0px 0px 10px 10px;
  margin: 20px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.modal_main {
  height: 450px;
  overflow: auto;
  padding: 0px 20px 20px 20px;
}
.addArea {
  padding: 20px;
}
.updateName {
  padding: 20px;
}
</style>